
export default [
    {
        type: 'selection',
        width: 38,
        align: 'center'
    },
    {
        title: 'Date Applied',
        key: 'AppliedOn',
        minWidth: 80,
		// sortable: true,
    },
    {
        title: 'OD Dates',
        key: 'ODDate',
        // sortable: true,
        minWidth: 80,
    },

	{
        title: 'Start Time',
        key: 'StartTime',
		// sortable: true,
        minWidth: 80,
    },

    {
        title: 'End Time',
        key: 'EndTime',
		// sortable: true,
        minWidth: 80,
    },
    {
        title: 'Status',
        key: 'ApprovalStatus',
        minWidth: 80,
		sortable: true,
    },
    {
        title: 'Note',
        key: 'Reason',
        minWidth: 80,
		// sortable: true,
    },


]
